/* eslint-disable no-console,no-undef */
import React, { Suspense } from 'react';
import { SubContainerBehavior, ContainerFirst, ContainerLast, HeadingMain } from './styles';
import { convertDateInTimezone, getTimeToShow } from "../../../utils/methods";
import moment from "moment";
import PropTypes from 'prop-types';
const DemoPopup = React.lazy(() => import('../../WhyAmigoCommon/ChallengePopup'));
import { connect } from 'react-redux';
import { getChallengeDetails, updateDailyTask } from '../../../redux/actions';
import { isNull, isUndefined } from 'lodash';
import { LoaderContainer } from "../../Content/styles";
import Loading from '../../Loading';
import { withTranslation } from 'react-i18next';
import Waiting from '../../Waiting';

class ActiveBehaviorChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: [],
      showVideo: false,
      objIndex: 0,
      dailyObj: 0,
      time: [],
      taskUpdated: 0,
      selectedDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
      activeDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
    };
  }

  componentDidMount() {
    const { activeChallengeShow, getChallengeDetails, liveChallengeStatus } = this.props;
    const { selectedDate } = this.state;
    getChallengeDetails(activeChallengeShow.id);
    this.setState({
      time: getTimeToShow(activeChallengeShow, 'challenge'),
      activeDate: selectedDate
    }, () => !liveChallengeStatus && this.daysDetails(this.state?.time, this.state?.activeDate))
  }

  daysDetails = (time, activeDate) => {
    const { activeChallengeShow } = this.props;
    const { taskUpdated, selectedDate, objIndex } = this.state;
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(activeChallengeShow.end_date)._d >= moment()._d;
    const joinOrLeave = !challengeOver && (activeChallengeShow.challenge_status === 'joined' ? 0 : 1);
    let today = moment()._d;
    let startDay = convertDateInTimezone(activeChallengeShow.start_date)._d;
    let endDate = convertDateInTimezone(activeChallengeShow.end_date)._d;
    let arr = [];
    let index = 0;
    let currentLength = moment(today, 'MM DD YYYY').diff(startDay, 'days');
    if (currentLength >= 0) {
      while (index <= (currentLength + 1)) {
        let dateObj = moment(startDay, 'MM DD YYYY').add(index, 'days').format('MM-DD-YYYY');
        arr.push(dateObj);
        index++;
      }
    }
    let selectedDay = taskUpdated ? selectedDate : activeDate;
    let objDayIndex = taskUpdated ? objIndex : arr.findIndex((date) => selectedDay == date);
    let dailyObj = (objDayIndex >= 0) ? activeChallengeShow?.behavior_challenge_details[objDayIndex] : {};
    let daysLeft = moment(endDate, 'MM DD YYYY').diff(today, 'days');
    let currentData = selectedDay == moment(today, 'MM DD YYYY').format('MM-DD-YYYY');
    this.setObjIndex(objDayIndex);
    this.setState({ valid: valid, joinOrLeave: joinOrLeave, dailyObj: dailyObj, daysLeft: daysLeft, startDay: startDay, currentData: currentData });
  }

  setObjIndex = (index, taskUpdated = 0, selectedDate = '') => {
    this.setState({ objIndex: index, taskUpdated: taskUpdated, selectedDate: selectedDate })
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo
    }));
  }

  updateStatus = () => {
    this.setState((prevState) => ({
      showCompletedStatus: !prevState.showCompletedStatus
    }))
  };

  updateDailyTaskAction = (bool, index) => {
    const { activeChallengeShow, updateDailyTask } = this.props;
    const { objIndex } = this.state;
    let obj = {
      id: activeChallengeShow.id,
      task_completed: bool,
      day_key: `day_${index}`
    };
    updateDailyTask(obj);
    this.setObjIndex(objIndex, 1, this.state.activeDate);
  };

  challengeDetails = () => {
    const { activeChallengeShow, history } = this.props;
    history.push(`/challenges/${activeChallengeShow.id}`)
  }

  challengesPageRedirection = () => {
    const { history } = this.props;
    history.push('/challenges');
  }

  render() {
    const { activeChallengeShow, challenge, indexCount, challengeLength, t } = this.props;
    const { showVideo, objIndex, dailyObj } = this.state;
    let today = moment()._d;
    let startDay = convertDateInTimezone(activeChallengeShow.start_date)._d;
    if (isNull(challenge) || isUndefined(challenge) || isUndefined(challenge.behavior_challenge_details) || isUndefined(objIndex)) {
      return <LoaderContainer><Loading /></LoaderContainer>
    }
    return (
      <React.Fragment>
        {isUndefined(dailyObj) ? null :
          <div>
            {indexCount === 0 && today > startDay && <HeadingMain>
              <div>
                <div>{t("My Active Challenges")}</div>
                {challengeLength.length > 1 && <div><div onClick={() => this.challengesPageRedirection()}>{t("See All >")}</div></div>}
              </div>
            </HeadingMain>}
            {activeChallengeShow.challenge_type === "behavior" && today > startDay &&
              <SubContainerBehavior >
                <div>
                  <ContainerFirst>
                    <div>{activeChallengeShow.title}</div>
                    {dailyObj['video_title'] === null ? <div>{t("Day")} {objIndex + 1}</div> : <div>{t("Day")} {objIndex + 1}: {dailyObj['video_title']}</div>}
                    {challenge && !isUndefined(challenge) && !isUndefined(challenge.behavior_challenge_details[objIndex].task_completed) && challenge.behavior_challenge_details[objIndex].task_completed === 1 ?
                      <div><button>Task is already completed</button></div> : <div>
                        {dailyObj['video_url'] === null ? "" : <button onClick={() => this.changeModal()}>{t("Start Today's Task")}</button>}
                      </div>
                    }
                  </ContainerFirst>
                </div>
                <div>
                  <ContainerLast background={challenge && !isUndefined(challenge.behavior_challenge_details[objIndex].task_completed) && challenge.behavior_challenge_details[objIndex].task_completed === 1  ? "linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)" : "rgb(105,194,255)"}>
                    <div>{t("Today's Tip")}</div>
                    <div>{dailyObj['tip']}</div>
                    <div>
                      <div>{t("Did you complete today's task?")}</div>
                      <div>
                        {challenge && !isUndefined(challenge) && !isUndefined(challenge.behavior_challenge_details[objIndex].task_completed) && challenge.behavior_challenge_details[objIndex].task_completed === 1 ?
                          <button>
                            {t("Completed")}
                          </button> :
                          <button
                            onClick={() => this.updateDailyTaskAction(1, objIndex)}>
                            {t("Yes")}
                          </button>}
                      </div>
                    </div>
                    <div onClick={() => this.challengeDetails()}>{t("see challenge details")}</div>
                  </ContainerLast>
                </div>
              </SubContainerBehavior>}
          </div>}
        <Suspense fallback={<Waiting/>}>{showVideo && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={dailyObj['video_url']} VideoTitle={dailyObj['video_title']} />}</Suspense>
      </React.Fragment>
    )
  }
}

ActiveBehaviorChallenge.propTypes = {
  history: PropTypes.object.isRequired,
  activeChallengeShow: PropTypes.object,
  liveChallengeStatus: PropTypes.bool,
  challenge: PropTypes.object,
  getChallengeDetails: PropTypes.func.isRequired,
  updateDailyTask: PropTypes.func,
  indexCount: PropTypes.number,
  challengeLength: PropTypes.object,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  challenge: state.challenges.challengeDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getChallengeDetails: (id) => dispatch(getChallengeDetails(id)),
  updateDailyTask: (obj) => dispatch(updateDailyTask(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActiveBehaviorChallenge));